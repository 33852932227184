body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2em;
  color: blue;
}

.links {
  display: flex;
  flex-wrap:wrap;
  background: white;
  align-items: stretch;
  align-content: stretch;
}

.link {
  width:20%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  text-transform: capitalize;
}


@media (max-width: 600px) {
  .link {
    width:50%;
  }
}
@media (max-width: 400px) {
  .link {
    width:100%;
  }
}

.strike{
  text-decoration: line-through;
}

.link:hover, .title:hover {
  background: blue;
  color: white!important;
  cursor: pointer;
}

.title{
  text-decoration: none;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}
